html {
	background: #f9fafe;
	color: #000;
	scroll-behavior: smooth;
	height: 100%;
}

body {
	height: 100%;
	min-height: 100vh;
	padding: 0;
	margin: 0;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
	font-family: Inter, Helvetica, Apple Color Emoji, Segoe UI Emoji,
		NotoColorEmoji, Noto Color Emoji, Segoe UI Symbol, Android Emoji,
		EmojiSymbols, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Helvetica Neue, Noto Sans, sans-serif;
	letter-spacing: 1px;
	font-size: clamp(0.8rem, 1.4vw, 0.9rem);
	-webkit-tap-highlight-color: transparent;
}

::selection {
	background-color: #f0bd66 !important;
	color: #fff !important;
}

/* 52B0E9 */

/* 2798da */

:root {
	--colorPrimary: #03a9f4;
	--colorSecondary: #bfc8d4;
	--colorSpanBorder: #1d1e23;
	--appBlack: #1d2022;
	--fontLight: #d8d8d8;
	--blueGradient: linear-gradient(320.66deg, #005ea3 14.75%, #11a8fd 84.81%);
	--blackGradient: linear-gradient(165deg, #27292d, #17191b);
	--darkShadow: #181a1b;
	--lightShadow: #393a3c;
	--darkLeftLinear: #17191b;
	--darkRightLinear: #27292d;
	--blueLeftLinear: #005ea3;
	--blueRightLinear: #11a8fd;
}

/* button, p, div, span, label, input, textarea {
    font-size: 16px;
} */

button {
	user-select: none;
}

button::-moz-focus-inner {
	border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

/* Let's get this party started */

::-webkit-scrollbar {
	width: 12px;
	display: none;
}

/* Track */

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: black;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgb(36, 36, 36);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

#rangeSlider > span[data-index='0']::after,
#rangeSlider > span[data-index='1']::after {
	height: 20px;
	width: 20px;
	top: auto;
	left: auto;
	right: auto;
	bottom: auto;
	background: var(--appBlack);
	box-shadow: inset 2px 2px 5px #090a0b, inset -2px -2px 5px #383d43;
	display: flex;
	justify-content: center;
	align-items: center;
}

#rangeSlider > span:nth-child(1) {
	background: #e0e0e0 !important;
	mix-blend-mode: normal;
	opacity: 0.71;
	border-radius: 50px !important;
	height: 5px !important;
}

#rangeSlider > span:nth-child(2) {
	height: 5px !important;
	border-radius: 50px !important;
	box-shadow: 8px 8px 16px #171717, -8px -8px 16px #35373a,
		inset 3px 4px 5px #2f3033, inset -2px -2px 5px #151719;
}

#rangeSlider > span[data-index='0']::before,
#rangeSlider > span[data-index='1']::before {
	top: 26px;
	content: '3.2';
	font-size: 12px;
	display: block;
	position: absolute;
	color: var(--fontLight);
	font-weight: bold;
	width: max-content;
}

#rangeSlider > span[data-index='0'] > span,
#rangeSlider > span[data-index='1'] > span {
	display: none;
}

@media (max-width: 700px) {
	* {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
	}
	*::-webkit-scrollbar {
		display: none;
	}
}
